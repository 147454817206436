import React from 'react'
import '../styles/about.css';

const About = () => {

  return (
    <section id="about" className="about">
      <div className="container">
        <div className="section-title">
          {/* <h2>About Us</h2> */}
          {/* <p>About Us</p> */}
        </div>

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="about-img">
              <img src="assets/img/about.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h3>Crafting Beverage Excellence in Jharkhand: Our Story</h3>
            {/* <p className="fst-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua.
            </p> */}
            {/* <ul>
              <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
              <li><i className="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
              <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
            </ul> */}
            <br />
            <p>
              Founded just two years ago in the vibrant city of Ranchi, our journey has been one of rapid growth and unwavering commitment. Over this short span, we have proudly extended our reach across Jharkhand, granting the opportunity to nine enterprising individuals to join our family as franchise partners.
            </p>
            <br />
            <p>
              At our core, we are passionate about beverages. We strive to create a delightful experience for our customers by offering an extensive range of beverages that cater to all tastes and preferences. Whether you're in the mood for a refreshing cup of coffee, a soothing tea, or a tantalizing blend of flavors, we've got you covered.
            </p>
          </div>
        </div>

      </div>
    </section>
  )
}

export default About