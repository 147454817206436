import { useState } from 'react';
import '../styles/contact.css';

const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    model: '',
    time: '',
    description: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Use Google Sheets API endpoint
      const sheetId = process.env.REACT_APP_SHEET_ID
      const apiKey = process.env.REACT_APP_API_KEY

      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/Sheet1?key=${apiKey}`;

      // Format data to be sent to Google Sheets
      const rowData = Object.values(formData);
      const values = [rowData];

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          values,
        }),
      });

      if (response.ok) {
        console.log('Data successfully submitted.');
        // Reset form fields or redirect as needed
        setFormData({
          name: '',
          email: '',
          phone: '',
          city: '',
          model: '',
          time: '',
          description: ''
        })
      } else {
        console.error('Error submitting data.');
        setFormData({
          name: '',
          email: '',
          phone: '',
          city: '',
          model: '',
          time: '',
          description: ''
        })
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleChange = (e) => {
    setFormData((data) => ({
      ...data,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <section id="contact" className="contact" style={{ color: "black" }}>
      <div className="container">

        <div className="section-title">
          <h2>Build a good relationship with your franchisees</h2>
          <p>Contact Us</p>
        </div>
      </div>

      <div className="container">

        <div className="row mt-5">

          <div className="col-lg-4">
            <div className="info">

              <div className="address">
                <a href="https://maps.app.goo.gl/ZeWZxE5mWSpNEPhp8" target='_blank'>
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Ranchi, Jharkhand</p>
                </a>
              </div>

              <div className="email">
                <a href="mailto:info@cvscafe.com">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>info@cvscafe.com</p>
                </a>
              </div>

              <div className="phone">
                <a href="tel:+916201145671" target="_blank">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 6201145671</p>
                </a>
              </div>

            </div>

          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">

            <form role="form" className="php-email-form" onSubmit={handleSubmit}>

              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" value={formData.name} className="form-control" placeholder="John Doe" onChange={handleChange} required />
                </div>

                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" value={formData.email} placeholder="john@mail.com" onChange={handleChange} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="phone" className="form-control" value={formData.phone} placeholder="123-456-7890" onChange={handleChange} required />
                </div>

                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="text" className="form-control" name="city" value={formData.city} placeholder="City name" onChange={handleChange} required />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group bg-gray">
                  <select type="text" name="model" className="form-control" value={formData.model} onChange={handleChange} required style={{ backgroundColor: "#0c0b09", color: "white" }}>
                    <option value="">Select Model</option>
                    <option value="Kiosk">Kiosk 150-350 sq.ft.</option>
                    <option value="premium">QSR 500-650 sq.ft.</option>
                    <option value="fine dine">Restaurant 800-2000 sq.ft.</option>
                  </select>
                </div>

                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <select type="text" name="time" className="form-control" value={formData.time} onChange={handleChange} required style={{ backgroundColor: "#0c0b09", color: "white" }}>
                    <option value="">Want to open</option>
                    <option value="immediate">Immediate</option>
                    <option value="3">in 3 months</option>
                    <option value="6">in 6 months</option>
                    <option value="12">in 12 months</option>
                  </select>
                </div>
              </div>

              <div className="form-group mt-3">
                <textarea className="form-control" name="description" rows="8" value={formData.description} placeholder="I am interested in opening your franchise. Please tell me more about your franchise opportunity and how I can get involved." onChange={handleChange} required></textarea>
              </div>

              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">One of our support team will contact you soon. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Submit</button></div>
            </form>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <iframe title='address' style={{ border: "0", width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234447.1561442902!2d85.15616713716075!3d23.343433459461956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e104aa5db7dd%3A0xdc09d49d6899f43e!2sRanchi%2C%20Jharkhand!5e0!3m2!1sen!2sin!4v1702722905416!5m2!1sen!2sin" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        {/* <iframe title='address' style={{ border: "0", width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d915.7226381552892!2d85.31267462840167!3d23.35597942989269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e054cc00941f%3A0xb7fda5dc846ebe0a!2s220%2C%20New%20AG%20Colony%20Rd%2C%20AG%20Colony%2C%20Ranchi%2C%20Jharkhand%20834002!5e0!3m2!1sen!2sin!4v1699767566639!5m2!1sen!2sin" allowFullScreen></iframe> */}
      </div>
    </section>
  )
}

export default Contact