import React from 'react'
import '../styles/franchise-hero.css';
import kiosk from "../media/kiosk.jpg"
import qsr from "../media/qsr.jpg"
import restaurant from "../media/restaurant.jpg"
import Contact from './Contact';
const Franchise = () => {
  return (
    <>
      <section id="franchise_hero" className="d-flex align-items-center">
        <div
          className="container position-relative text-center text-lg-start"
        >
          <div className="row">
            <div className="col-lg-8">
              {/* <h1>Welcome to <span>CVS Cafe`</span></h1> */}
              <h1>Unleash Your Entrepreneurial Spirit: Join CVS Cafe and Own the Future of Flavor</h1>
            </div>
          </div>
        </div>
      </section>

      <section style={{display: 'flex', justifyContent: "space-evenly"}}>
        <div className="card" style={{ width: "18rem" }}>
          <img className="card-img-top" src={kiosk} alt="Card image cap" />
          <div className="card-body">
            <h4 className="card-title">Kiosk</h4>
            <p className="card-text">"Indulge in the savory success of CVS Cafe with our dynamic Quick Service Restaurant (QSR) model! Elevate your entrepreneurial journey with a prime space of 500-650 sq ft and an investment range of 12-16 lakh. Experience the perfect blend of efficiency and flavor as you cater to a diverse clientele. Our QSR model offers a robust investment opportunity with swift returns, a comprehensive support system, and the renowned taste that defines CVS Cafe. Unleash your passion for quality dining and join us in crafting a delectable experience for every customer!"</p>
          </div>
        </div>
        <div className="card" style={{ width: "18rem" }}>
          <img className="card-img-top" src={qsr} alt="Card image cap" />
          <div className="card-body">
            <h4 className="card-title">QSR Model</h4>
            <p className="card-text">"  "Indulge in the savory success of CVS Cafe with our dynamic Quick Service Restaurant (QSR) model! Elevate your entrepreneurial journey with a prime space of 500-650 sq ft and an investment range of 12-16 lakh. Experience the perfect blend of efficiency and flavor as you cater to a diverse clientele. Our QSR model offers a robust investment opportunity with swift returns, a comprehensive support system, and the renowned taste that defines CVS Cafe. Unleash your passion for quality dining and join us in crafting a delectable experience for every customer!""</p>
          </div>
        </div>
        <div className="card" style={{ width: "18rem" }}>
          <img className="card-img-top" src={restaurant} alt="Card image cap" />
          <div className="card-body">
            <h4 className="card-title">Restaurant Model</h4>
            <p className="card-text">"Indulge in the extraordinary with CVS Cafe Restaurant Model – where passion meets flavor in spaces ranging from 800-2000 sq ft. With an investment of 15-30 lakh, immerse yourself in the art of culinary excellence. This model offers a spacious canvas for your entrepreneurial dreams, promising a rich dining experience for patrons. Elevate your investment to gastronomic heights, backed by our established brand, comprehensive support, and the promise of a rewarding journey. Join us in crafting a dining destination that goes beyond the ordinary – become a proud owner of a CVS Cafe restaurant today!"</p>
          </div>
        </div>
{/* 
        <div className="max-w-sm rounded overflow-hidden shadow-lg">
          <img className="w-full" src={kiosk} alt="Sunset in the mountains" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">Kiosk Model</div>
            <p className="text-base">
              "Indulge in the savory success of CVS Cafe with our dynamic Quick Service Restaurant (QSR) model! Elevate your entrepreneurial journey with a prime space of 500-650 sq ft and an investment range of 12-16 lakh. Experience the perfect blend of efficiency and flavor as you cater to a diverse clientele. Our QSR model offers a robust investment opportunity with swift returns, a comprehensive support system, and the renowned taste that defines CVS Cafe. Unleash your passion for quality dining and join us in crafting a delectable experience for every customer!"
            </p>
          </div>
        </div> */}
      </section>
    </>
  )
}

export default Franchise