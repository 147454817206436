import { Routes, Route } from 'react-router-dom';
import Home from "./components/Home";
import Header from "./components/Header"
import About from "./components/About"
import Contact from "./components/Contact"
import Footer from "./components/Footer"
import Franchise from './components/Franchise';
import Gallery from './components/Gallery';

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<Gallery />} />
        <Route path="/:id" element={<Contact />} />
        <Route path="/:id" element={<About />} />
        <Route path="/franchise" element={<Franchise />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App;