// import { Swiper, SwiperSlide } from "swiper/react"
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useRef } from "react";
import { Navigation, Pagination } from 'swiper/modules';
import '../styles/testimonial.css';

const Testimonials = () => {
  const swiperRef = useRef(null);
  useEffect(() => {
    swiperRef.current = new Swiper('.testimonials-slider', {
      modules: [Navigation, Pagination],
      speed: 600,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },

        1200: {
          slidesPerView: 3,
          spaceBetween: 20
        }
      }
    });

    return () => {
      // Destroy the Swiper instance when the component unmounts
      if (swiperRef.current) {
        swiperRef.current.destroy();
      }
    };
  }, []);

  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container">

        <div className="section-title">
          <h2>Testimonials</h2>
          <p>What they're saying about us</p>
        </div>

        <div className="testimonials-slider">
          <div className="swiper-wrapper">

          <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  I've been a loyal customer since day one, and the consistent quality of beverages keeps me coming back. From aromatic coffees to soothing teas, they've mastered the art of crafting the perfect drink. Their commitment to excellence is evident, and I'm delighted to be a part of their growing fan base.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" />
                <h3>Keshav Priyadarshi</h3>
                <h4>Customer</h4>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Exceptional service and an incredible variety of beverages! As a proud franchise owner, I've witnessed firsthand the dedication and passion that this company pours into every cup. It's been a rewarding journey, and I'm thrilled to be a part of this thriving community.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt="" />
                <h3>Anshumann</h3>
                <h4>Franchise Owner</h4>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>
  )
}

export default Testimonials