import React from 'react'
import '../styles/footer.css';

const Footer = () => {
  return (
    <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h3 className='d-flex align-items-center space-x-4 space-x-reverse'>
                <span>
                  <img className='h-11' src="assets/img/logo.png" alt="" />
                </span> CVS Cafe</h3>
              <p>
                {/* AG Colony, Sahjanand Chowk <br/> */}
                Ranchi, Jharkhand<br/><br/>
                <strong>Phone:</strong> +91 6201145671<br/>
                <strong>Email:</strong> info@cvscafe.com<br/>
              </p>
              <div className="social-links mt-3">
                {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
                {/* <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a> */}
                <a href="https://instagram.com/cvscafe_india?igshid=MzMyNGUyNmU2YQ==" rel='instagram profile' target='_blank' className="instagram"><i className="bx bxl-instagram"></i></a>
                {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a> */}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            {/* <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <Link to="/contact">Contact Us</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="#">Careers</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/franchise">Franchise</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="#">Marketing</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="#">Graphic Design</Link></li>
            </ul> */}
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
          </div>

          <div className="col-lg-3 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Will start publishing soon</p>
            {/* <form action="" method="post">
              <input type="email" name="email"/>
              <input type="submit" value="Subscribe"/>
            </form> */}

          </div>

        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span>CVS Cafe</span></strong>. All Rights Reserved
      </div>
      {/* <div className="credits">
        Designed by <a href="">Keshav Priyadarshi</a>
      </div> */}
    </div>
  </footer>
  )
}

export default Footer