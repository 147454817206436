import React from 'react'
import '../styles/hero.css';

const Hero = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div 
      className="container position-relative text-center text-lg-start" 
      >
        <div className="row">
          <div className="col-lg-8">
            <h1>Welcome to <span>CVS Cafe`</span></h1>
            <h2>Delivering great food for more than 2 years!</h2>

            <div className="btns">
              {/* <Link to="/menu" className="btn-menu animated fadeInUp scrollto">Our Menu</Link> */}
              {/* <Link to="/franchise" className="btn-book animated fadeInUp scrollto">Take a Franchise</Link> */}
              <a href="#contact" className="btn-book animated fadeInUp scrollto">Take a Franchise</a>
            </div>
          </div>
          {/* <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative">
            <link href="https://www.youtube.com/watch?v=u6BOC7CDUTQ" className="glightbox play-btn"/>
          </div> */}

        </div>
      </div>
    </section>
  )
}

export default Hero