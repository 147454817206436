import { Link, useNavigate } from "react-router-dom"
import '../styles/header.css'
import '../styles/navbar.css'

const Header = () => {
    const navigate = useNavigate();
    const navigationHandler = (pathName)=>{
        navigate(pathName, { replace: true });
    }

    return (
        < nav id="header" className="fixed w-full z-20 top-0 start-0" >
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
                <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse px-3">
                    <img src="assets/img/logo.png" className="display: block h-11" alt="CVS cafe Logo" />
                    {/* <span className="self-center text-2xl font-semibold whitespace-nowrap">CVS Cafe`</span> */}
                </Link>

                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                    <ul className="flex flex-col md:p-0 font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0">
                        <li>
                            {/* <Link to="/" className="block py-2 px-3" aria-current="page">Home</Link> */}
                            <a className="block py-2 px-3" href="#hero" onClick={() => navigationHandler('/#hero')}>Home</a>
                        </li>
                        <li>
                            {/* <Link to="/" className="block py-2 px-3">About</Link> */}
                            <a className="block py-2 px-3" href="#about" onClick={() => navigationHandler('/#about')}>About</a>
                        </li>
                        <li>
                            {/* <Link to="/" className="block py-2 px-3">Gallery</Link> */}
                            <a className="block py-2 px-3" href="#gallery" onClick={() => navigationHandler('/#gallery')}>Gallery</a>
                        </li>
                        <li>
                            {/* <button to="/" className="block py-2 px-3">Franchise</button> */}
                            <a className="block py-2 px-3" href="#contact" onClick={() => navigationHandler('/#contact')}>Contact</a>
                        </li>
                        <li>
                            {/* <button to="/" className="block py-2 px-3">Franchise</button> */}
                            <Link to="/franchise" className="block py-2 px-3">Franchise</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    )
}

export default Header