import Hero from "./Hero"
import About from "./About"
import Gallery from "./Gallery"
import Testimonials from "./Testimonials"
import Contact from "./Contact"

const Home = () => {
    return (
        <>
            <Hero />
            <About/>
            <Gallery />
            <Testimonials />
            <Contact />
        </>
    )
}

export default Home